<template>
  <form class="form">

    <FveBlockSwitchActive
      v-if="editForm"
      v-model="form.is_active"
      class="fix-swich"
    />

    <fieldset class="form__fieldset">
      <div class="row">
        <div class="col-3">
          <FveFileImageCropperPreview
            label=""
            name="avatar"
            v-model="form.avatar"
          />
        </div>
        <div class="col-9">
          <FveText
            label="Название"
            name="name"
            required
            v-model="form.name"
          />
          <FveSelectAjaxV2Address
            label="Адрес"
            name="adress"
            required
            v-model="form.address"
          />
          <span
            v-if="!addressFormDetails"
            @click="addressFormDetails=!addressFormDetails"
            style="cursor: pointer; color: var(--text-descr); position: relative; top: -20px;"
          >Редактировать вручную</span>

          <FveSelectAjaxV2AddressUser
            v-if="addressFormDetails"
            label="Детали адреса"
            required
            v-model="form.address"
          />

          <!-- <FveText
            label="Адрес"
            name="adress"
            required
            v-model="form.address"
          /> -->
          <FveTextarea
            label="Информация"
            name="about"
            v-model="form.about"
          />
        </div>
      </div>
      <fieldset class="form__fieldset">
        <legend class="form__legend">Контакты</legend>
        <div class="row row-cols-5">
          <div class="col">
            <FveText
              label="Телефон"
              placeholder="+7 (___) ___-__-__"
              v-mask="'+7 (###) ###-##-##'"
              name="contact_phone"
              v-model="form.phone"
            />
          </div>
          <div class="col">
            <FveText
              label="Сайт"
              placeholder="https://site.com"
              name="contact_site"
              v-model="form.site"
            />
          </div>
        </div>
        <div class="row row-cols-5">
          <div class="col">
            <FveText
              label="Телеграм"
              placeholder="@telegram"
              name="contact_telegram"
              v-model="form.contactTelegram"
            />
          </div>
          <div class="col">
            <FveText
              label="E-mail"
              placeholder="mail"
              name="contact_email"
              v-model="form.contactEmail"
            />
          </div>
          <div class="col">
            <FveText
              label="Twitter"
              placeholder="twitter"
              name="contact_twitter"
              v-model="form.contactTwitter"
            />
          </div>
          <div class="col">
            <FveText
              label="Whatsapp"
              placeholder="whatsapp"
              name="contact_whatsapp"
              v-model="form.contactWhatsapp"
            />
          </div>
          <div class="col">
            <FveText
              label="Instagram"
              placeholder="instagram"
              name="contact_instagram"
              v-model="form.contactInstagram"
            />
          </div>
        </div>
      </fieldset>
      <fieldset class="form__fieldset">
        <legend class="form__legend">Удобства</legend>

        <div class="club-panel__feature-list">
          <template v-for="(item, index) in comfortInfoList">
            <div class="club-panel__feature"
              :key="'feature-' + index"
              :id="'tooltip-item' + index"
              @click="toggleComfortItem(item)"
            >
              <img :src="item.img" />
              <div
                class="club-panel__feature-overlay"
                v-if="!form.feature_set.find(f => f.comfort === item.comfort)"
              ></div>
              <b-tooltip :target="'tooltip-item' + index" variant="danger" placement="right">
                {{ item.name }}
              </b-tooltip>
            </div>
          </template>
        </div>
      </fieldset>
    </fieldset>
  </form>
</template>

<script>

import FveText from "@widgetFormValidate/src/Element/Text/FveText";
import FveTextarea from "@widgetFormValidate/src/Element/Text/FveTextarea";
import FveFileImageCropperPreview from "@widgetFormValidate/src/Element/File/FveFileImageCropperPreview";
import FveSelectAjaxV2Address from "@widgetFormValidate/src/Element/Custom/FveSelectAjaxV2Address";
import FveSelectAjaxV2AddressUser from "@widgetFormValidate/src/Element/Custom/FveSelectAjaxV2AddressUser";
//
import { comfortInfoList } from '../../page/Club/_component/helper';
import FveFormMixin from "@widgetFormValidate/src/Mixin/FveFormMixin";
import FveBlockSwitchActive from "@widgetFormValidate/src/Element/Switch/FveBlockSwitchActive";

export default {
  mixins: [
    FveFormMixin
  ],
  props: {
    editForm: {type: Boolean, default: false}
  },
  components: {
    FveBlockSwitchActive,
    FveText,
    FveTextarea,
    FveFileImageCropperPreview,
    FveSelectAjaxV2Address,
    FveSelectAjaxV2AddressUser
  },
  data(){
    return {
      tagList: [],
      addressFormDetails: false,
      comfortInfoList
    };
  },
  methods: {
    formSchema() {
      return {
        //
        is_active    : { type: Boolean, default: () => { return true; } },
        //
        avatar      : { type: FileClass, default: () => { return null; } },
        name        : { type: String, default: () => { return ''; } },
        // address     : { type: String, default: () => { return ''; } },
        address     : { type: Object, default: () => { return null; } },
        about       : { type: String, default: () => { return ''; } },

        phone       : { type: String, default: () => { return ''; } },
        site        : { type: String, default: () => { return ''; } },

        contactTelegram   : { type: String, default: () => { return ''; } },
        contactEmail      : { type: String, default: () => { return ''; } },
        contactTwitter    : { type: String, default: () => { return ''; } },
        contactWhatsapp   : { type: String, default: () => { return ''; } },
        contactInstagram  : { type: String, default: () => { return ''; } },

        feature_set       : { type: Array,  default: () => { return []; } },
      };
    },
    toggleComfortItem(item) {
      const index = this.form.feature_set.findIndex(f => f.comfort === item.comfort);
      if(index === -1) {
        this.form.feature_set.push(item);
      } else {
        this.form.feature_set.splice(index, 1);
      }
    }
  },
  // created() {
  //   RequestManager.Reference.getClubFeatureList(1).then((list) => {
  //   });
  // }
};

</script>

<style lang="scss" scoped>
.club-panel__feature-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 18px;
  .club-panel__feature {
    position: relative;
    cursor: pointer;
    margin-right: 4px;
    &:last-child {
      margin-right: 0;
    }
    &-overlay {
      display: block;
      width: 32px;
      height: 32px;
      position: absolute;
      background: #1b1b1ba6;
      top: 0;
    }
  }
}
</style>
