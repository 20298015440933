<template>
  <div class="container">
    <div class="page-header">
      <div class="page-header__ell">
        <h1 class="page-title">Редактировать клуб</h1>
      </div>
    </div>
    <ClubEditForm
      ref="ClubEditForm"
      :formData="club"

      :editForm="true"
    />
    <form class="form">
      <div class="btn-gp">
        <b-button size="lg" variant="danger" @click="update">Сохранить</b-button>
        <router-link :to="{ name: this.$routeName.CLUB_LIST }" class="btn btn-link btn-lg">Отмена</router-link>
      </div>
    </form>
  </div>
</template>

<script>

import ClubEditForm from "@component/Form/ClubEditForm";
import { getComfortItem } from './_component/helper';
//
import GetGoogleMap from "@library/GetGoogleMap";

export default {
  name: "ClubEdit",
  components: {
    ClubEditForm,
  },
  props: {
    clubId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      club: {}
    };
  },
  methods: {
    async update() {

      let formData = this.$refs.ClubEditForm.formSubmitGetData();
      if(!formData) { return; }

      let sendData = Object.assign({}, formData);

      if(sendData.phone) {
        sendData.phone = sendData.phone.replace(/-|\s+/g,"");
      }
      //
      const contact_set = [];

      if(sendData.contactTelegram || sendData.contactTelegram !== '-'  ) { contact_set.push({ type: "TGRM", value: sendData.contactTelegram  }); }
      if(sendData.contactEmail || sendData.contactEmail !== '-'     ) { contact_set.push({ type: "MAIL", value: sendData.contactEmail     }); }
      if(sendData.contactTwitter || sendData.contactTwitter !== '-'   ) { contact_set.push({ type: "TWTR", value: sendData.contactTwitter   }); }
      if(sendData.contactWhatsapp || sendData.contactWhatsapp !== '-'  ) { contact_set.push({ type: "WTSP", value: sendData.contactWhatsapp  }); }
      if(sendData.contactInstagram || sendData.contactInstagram !== '-' ) { contact_set.push({ type: "INST", value: sendData.contactInstagram }); }

      delete sendData.contactTelegram;
      delete sendData.contactEmail;
      delete sendData.contactTwitter;
      delete sendData.contactWhatsapp;
      delete sendData.contactInstagram;

      // location set
      try {
        const gObj = await GetGoogleMap();
        const coord = await gObj.getAddressCoordinate(sendData.address.value);
        //
        sendData.address.data.geo_lat = coord.lat;
        sendData.address.data.geo_lon = coord.lon;
      } catch (e) {
        this.$dialogs.alert('Не удалось определить адресс');
      }
      
      sendData.location = JSON.stringify({
        latitude: sendData.address.data.geo_lat,
        longitude: sendData.address.data.geo_lon
      });
      sendData.address = sendData.address.value;

      // feature set
      const feature_set = sendData.feature_set.map(f => {
        return {
          comfort: f.comfort
        };
      });
      delete sendData.feature_set;

      try {
        // const res = await RequestManager.Club.addClub(sendData);
        const contact_set_string = JSON.stringify(contact_set);
        const feature_set_string = JSON.stringify(feature_set);
        // патчим контакты и/или удобства

        const clubUpdateObj = {
          ...sendData,
          contact_set: contact_set_string,
          feature_set: feature_set_string,
        };

        const res2 = await RequestManager.Club.updateClub({ clubId: this.clubId, club:  clubUpdateObj });
        this.$toasts.push({
          message: 'Клуб успешно обновлен',
          duration: 4000,
        });
        this.$router.push({ name: this.$routeName.CLUB_LIST });
      } catch (e) {
        console.error(e);
      }

    },
    load(){
      RequestManager.Club.getClubById({ id: this.clubId }).then(async (item) => {
        this.club = {
          img       : new FileClass({ src: item.avatar }),
          avatar    : new FileClass({ src: item.avatar }),
          name      : item.name,
          // address   : item.address,
          about     : item.about,
          ages      : item.ages, //TODO fix
          types     : item.types, //TODO fix
          tags      : item.tags.map(tagObject => tagObject.tag),
          phone     : item.phone,

          site     : item.site || '',

          contactTelegram  : '-',
          contactEmail     : '-',
          contactTwitter   : '-',
          contactWhatsapp  : '-',
          contactInstagram : '-',
          feature          : item.feature_set.map(tagObject => getComfortItem(tagObject.comfort)),
          feature_set      : item.feature_set.map(tagObject => getComfortItem(tagObject.comfort)),
          contact_set: item.contact_set,

          is_active: item.is_active
        };

        this.club.address = {
          data: {
            geo_lat: item.location.latitude,
            geo_lon: item.location.longitude,
          },
          value: item.address
        };

        for(let i = 0; i < this.club.contact_set.length; i++) {
          let obj = this.club.contact_set[i];
          switch (obj.type) {
            case 'TGRM': this.club.contactTelegram  = obj.value; break;
            case 'MAIL': this.club.contactEmail     = obj.value; break;
            case 'TWTR': this.club.contactTwitter   = obj.value; break;
            case 'WTSP': this.club.contactWhatsapp  = obj.value; break;
            case 'INST': this.club.contactInstagram = obj.value; break;
          }
        }


      });
    }
  },
  created() {
    this.load();
    RequestManager.Reference.getClubAgeList({}).then((list) => {
      const _ageList = {};
      list.forEach(i => _ageList[i.key] = i.value);
      this.ageList = _ageList;
    });
    RequestManager.Reference.getTrainGroupTypeList({}).then((list) => {
      const _typeList = {};
      list.forEach(i => _typeList[i.key] = i.value);
      this.typeList = _typeList;
    });
  }
};
</script>

<style lang="scss" scoped>

</style>
